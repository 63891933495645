<template>
  <v-container
    dense
    no-gutters
    class="d-flex align-center py-1 messagerie-list--mini--item rounded overflow-hidden"
    :class="rowClasses"
    v-ripple
  >
    <v-col cols="2" class="text-small px-1">
      <v-chip
        x-small
        :color="fiche.fiche.impact.value.id === 4 ? 'error' : 'primary'"
        dark
        label
        :title="$t('actions.ticket-copied')"
        class="elevation-2"
        @click.stop="
          copyToClipboard(makeUrl(fiche), $nSuccess($t('actions.ticket-copied')))
        "
      >
        {{ fiche.fiche.ticket }}
        <v-icon
          v-if="fiche.fiche.phone_be_contacted"
          @click.stop="
            copyToClipboard(fiche.fiche.phone, $nSuccess($t('actions.phone-copied')))
          "
          color="white"
          small
          right
          :title="$t('actions.phone-copied')"
          >mdi-phone-incoming-outline
        </v-icon>
      </v-chip>
    </v-col>
    <v-col
      cols="2"
      class="text-small px-1 d-flex flex-column align-center"
      :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''"
    >
      <!--      {{ fiche.fiche.client }}-->
      <v-chip
        x-small
        :color="ColorsUtils.getTypeColor(fiche.fiche.type_demande.value.id)"
        label
        dark
        class="elevation-1 px-1"
      >
        <span class="text-truncate">{{
          $t("messagerie.parametrages." + fiche.fiche.type_demande.text)
        }}</span>
      </v-chip>
    </v-col>
    <v-col :cols="user.superAdministrator ? 3 : 4"
     class="text-small px-1"  :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''"
      >{{
        getModuleName(fiche.fiche.module, fiche.fiche.sous_module, modules).module.name
      }}<span v-if="fiche.fiche.sous_module">
        -
        {{
          getModuleName(fiche.fiche.module, fiche.fiche.sous_module, modules).sous_module.name
        }}</span
      >
    </v-col>
    <v-col cols="3" class="text-small px-1">
      <v-chip
        x-small
        :color="getColorEtat(fiche.fiche.etat.value.id)"
        label
        dark
        class="font-weight-bold elevation-1 etiquette--fiche text-truncate px-1"
      >
        {{ $t("messagerie.parametrages." + fiche.fiche.etat.text) }}
      </v-chip>
    </v-col>
    <v-col
      v-if="
        user.superAdministrator &&
        fiche.fiche.appel_client &&
        fiche.fiche.appel_client.length > 0
      "
      cols=""
      class="text-small ml-4 mr-1"
    >
      <v-icon
        small
        color="green"
        :title="fiche.fiche.appel_client[fiche.fiche.appel_client.length - 1].user"
      >
        mdi-phone
      </v-icon>
    </v-col>
    <v-col
      v-else-if="
        user.superAdministrator &&
        fiche.fiche.appel_client &&
        fiche.fiche.appel_client.length == 0
      "
      class="text-small ml-4 mr-1"
    >
      <v-icon small color="#ccc" title="Pas d'appel client"> mdi-phone-hangup </v-icon>
    </v-col>
    <!-- sinon rien pour ne pas deranger l affichage css des non superadmin -->
    <v-col v-else cols="" class="text-small ml-4 mr-1"></v-col>
    <!-- afficher un icon quand la fiche est suivi -->
    <v-col
      v-if="user.superAdministrator && fiche.fiche.suivi"
      cols=""
      class="text-small mr-1"
    >
      <!-- afficher les droit -->
      <v-icon small color="#F0F" title="Fiche suivie"> mdi-bookmark-check </v-icon>
    </v-col>
    <v-col
      v-else-if="user.superAdministrator && !fiche.fiche.suivi"
      cols=""
      class="text-small mr-1"
    >
      <!-- icon fiche non suivi -->
      <v-icon small color="#ccc" title="Fiche non suivie"> mdi-bookmark-remove </v-icon>
    </v-col>
    <v-col v-else cols="" class="text-small mr-1"></v-col>
    <!-- icon ouvrir dans un nouvel onglet -->
    <v-col cols="" class="text-small">
      <a :href="makeUrl(fiche)" target="_blank" rel="noopener noreferrer" @click.stop>
        <v-icon color="primary" size="13" title="Ouvrir dans un nouvel onglet">
          mdi-open-in-new
        </v-icon>
      </a>
    </v-col>
    <menu-fiche
      :mini="true"
      :fiche="fiche"
      :etats="getMessagerieData().etats"
      :impacts="getMessagerieData().impacts"
      :ordre_priorites="getMessagerieData().ordre_priorites"
      :type_demandes="getMessagerieData().type_demandes"
      :rightsUser="getMessagerieData().rightsUser"
      :tagsPlaneteOnline="getMessagerieData().tagsPlaneteOnline"
      :users="getMessagerieData().users"
      :user="user"
    />
  </v-container>
</template>

<script>
import ColorsUtils from "@/Utils/MessagerieColors";
import moment from "moment";
import { mapGetters, mapState } from "vuex";

export default {
  name: "TicketCardMini",
  components: {
    Color: () => import("@/Components/Commons/UiElements/Color"),
    MenuFiche: () => import("@/Components/Views/Hotline/Ticket/TicketMenu"),
  },
  props: {
    fiche: Object,
    selected: Boolean,
    user: Object,
  },
  computed: {
    ...mapGetters(["getMessagerieData"]),
    ...mapState(["modules"]),
    rowClasses() {
      let classes = {};
      const userService = this.getUserService();
      const ticketStatus = this.getTicketStatus();

      classes["selected"] = this.selected;
      classes["urgency-fiche-inset"] = this.fiche.fiche.impact.value.id === 4;
      classes["lighten-5"] = this.selected;
      classes["more-visibilty"] = this.selected;

      if (this.$vuetify.theme.dark) {
        classes["theme-dark"] = true;
      } else {
        classes["theme-light"] = true;
      }

      if (userService === "hotline") {
        if (ticketStatus.isUnread) {
          if (ticketStatus.isClientMessage) {
            classes["unread-client"] = true;
            classes["unread-hotline"] = true;
          } else if (ticketStatus.isDevResponseToHotline) {
            classes["unread-internal"] = true;
          }
        } else if (ticketStatus.isReadNotAnswered) {
          if (ticketStatus.isClientMessage) {
            classes["read-not-answered-client"] = true;
          } else if (ticketStatus.isDevResponseToHotline) {
            classes["read-not-answered-internal"] = true;
          }
        }
      } else if (userService === "internal") {
        if (ticketStatus.isUnread && ticketStatus.isInternalResponse) {
          classes["unread-internal"] = true;
        } else if (ticketStatus.isReadNotAnswered && ticketStatus.isInternalResponse) {
          classes["read-not-answered-internal"] = true;
        }
      } else if (
        userService === "client" &&
        ticketStatus.isUnread &&
        ticketStatus.isHotlineMessage
      ) {
        classes["unread-client"] = true;
      }

      classes["new_fiche"] = this.fiche.fiche.new_fiche && !this.selected;
      classes["archived"] =
        (this.fiche.fiche.archived || this.fiche.fiche.old_fiche) && !this.selected;
      return classes;
    },
  },
  data() {
    return {
      ColorsUtils,
      show: false,
    };
  },
  methods: {
    makeUrl(fiche = null) {
      if (fiche) {
        return (
          "https://" + this.getDns() + "/planete-online/app/hotline?ticket=" + fiche._id
        );
      }
    },
    userNotSeen(fiche) {
      const userService = this.getUserService();

      if (fiche.fiche.serviceAnswer) {
        switch (fiche.fiche.serviceAnswer) {
          case "interne":
            return userService == "internal" ? this.getUnseenMessagesCount(fiche) : 0;
          case "hotline to client":
            return userService == "client" ? this.getUnseenMessagesCount(fiche) : 0;
          case "hotline to interne":
            return userService == "internal" ? this.getUnseenMessagesCount(fiche) : 0;
          case "client":
            return userService == "client" ? this.getUnseenMessagesCount(fiche) : 0;
          default:
            return userService == "hotline" ? this.getUnseenMessagesCount(fiche) : 0;
        }
      }

      return userService == "hotline" || userService == "client"
        ? this.getUnseenMessagesCount(fiche)
        : 0;
    },
    getUnseenMessagesCount(fiche) {
      if (fiche.fiche.usersSeen.length == 0) {
        return fiche.fiche.number_messages;
      } else {
        let userSeen = fiche.fiche.usersSeen.find((u) => u.idUser == this.user.id);
        if (typeof userSeen != "undefined") {
          return userSeen.nb;
        } else {
          return fiche.fiche.number_messages;
        }
      }
    },
    getUserService() {
      const messagerieData = this.$store.getters.getMessagerieData();
      const rightsUser = messagerieData.rightsUser;

      if (rightsUser.hotline) {
        return "hotline";
      } else if (!rightsUser.hotline && rightsUser.detailed_view) {
        return "internal";
      } else {
        return "client";
      }
    },
    getTicketStatus() {
      const lastMessage = this.fiche.fiche.last_message;
      const lastMessageInterne = this.fiche.fiche.last_message_interne;
      const userService = this.getUserService();

      let isUnread = this.userNotSeen(this.fiche) > 0;
      let isClientMessage = false;
      let isHotlineMessage = false;
      let isInternalResponse = false;
      let isDevResponseToHotline = false;
      let isReadNotAnswered = false;

      // console.log("Debug - Initial values:", {
      //   userService,
      //   isUnread,
      //   lastMessage: lastMessage ? lastMessage.serviceAnswer : "N/A",
      //   lastMessageInterne: lastMessageInterne ? lastMessageInterne.serviceAnswer : "N/A",
      // });

      if (userService === "hotline") {
        if (lastMessage.time > lastMessageInterne?.time) {
          isClientMessage = lastMessage.serviceAnswer === "client";
          isHotlineMessage = lastMessage.serviceAnswer === "hotline to client";
        }
        if (lastMessageInterne?.time > lastMessage.time) {
          isDevResponseToHotline = lastMessageInterne.serviceAnswer === "interne";
          isInternalResponse = lastMessageInterne.serviceAnswer === "hotline to interne";
        }
        isReadNotAnswered =
          !isUnread && (isClientMessage || isDevResponseToHotline || isInternalResponse);
      } else if (userService === "internal") {
        if (lastMessageInterne?.time > lastMessage.time) {
          // console.log("Debug - Last message interne:", lastMessageInterne);

          isInternalResponse = lastMessageInterne.serviceAnswer === "hotline to interne";
          isDevResponseToHotline = lastMessageInterne.serviceAnswer === "interne";
        }
        if (lastMessage.time > lastMessageInterne?.time) {
          // console.log("Debug - Last message:", lastMessage);

          isClientMessage = lastMessage.serviceAnswer === "client";
          isHotlineMessage =
            lastMessage.serviceAnswer === "hotline to client" ||
            lastMessage.serviceAnswer === "hotline to interne";
        }
        isReadNotAnswered =
          !isUnread &&
          (isInternalResponse ||
            isDevResponseToHotline ||
            isClientMessage ||
            isHotlineMessage);
      } else if (userService === "client") {
        if (lastMessage) {
          isHotlineMessage = lastMessage.serviceAnswer === "hotline to client";
          isClientMessage = lastMessage.serviceAnswer === "client";
        }
        isReadNotAnswered = !isUnread && isHotlineMessage;
      }

      // console.log("Debug - Final values:", {
      //   isUnread,
      //   isClientMessage,
      //   isHotlineMessage,
      //   isInternalResponse,
      //   isDevResponseToHotline,
      //   isReadNotAnswered,
      // });

      return {
        isUnread,
        isClientMessage,
        isHotlineMessage,
        isInternalResponse,
        isDevResponseToHotline,
        isReadNotAnswered,
      };
    },
    // getTicketStatus2() {
    //   const lastMessage = this.fiche.fiche.last_message;
    //   const lastMessageInterne = this.fiche.fiche.last_message_interne;
    //   const userService = this.getUserService();

    //   let isUnread = this.userNotSeen(this.fiche) > 0;
    //   let isClientMessage = false;
    //   let isHotlineMessage = false;
    //   let isInternalResponse = false;
    //   let isDevResponseToHotline = false;
    //   let isReadNotAnswered = false;

    //   // Déterminer le message le plus récent
    //   let mostRecentMessage;
    //   if (lastMessage && lastMessageInterne) {
    //     mostRecentMessage =
    //       new Date(lastMessage.time) > new Date(lastMessageInterne.time)
    //         ? lastMessage
    //         : lastMessageInterne;
    //   } else {
    //     mostRecentMessage = lastMessage || lastMessageInterne;
    //   }

    //   console.log("Debug - Initial values:", {
    //     userService,
    //     isUnread,
    //     lastMessage: lastMessage
    //       ? { serviceAnswer: lastMessage.serviceAnswer, time: lastMessage.time }
    //       : "N/A",
    //     lastMessageInterne: lastMessageInterne
    //       ? {
    //           serviceAnswer: lastMessageInterne.serviceAnswer,
    //           time: lastMessageInterne.time,
    //         }
    //       : "N/A",
    //     mostRecentMessage: mostRecentMessage
    //       ? {
    //           serviceAnswer: mostRecentMessage.serviceAnswer,
    //           time: mostRecentMessage.time,
    //         }
    //       : "N/A",
    //   });

    //   if (userService === "hotline") {
    //     if (lastMessage) {
    //       isClientMessage = lastMessage.serviceAnswer === "client";
    //       isHotlineMessage = lastMessage.serviceAnswer === "hotline to client";
    //     }
    //     if (lastMessageInterne) {
    //       isDevResponseToHotline = lastMessageInterne.serviceAnswer === "interne";
    //       isInternalResponse = lastMessageInterne.serviceAnswer === "hotline to interne";
    //     }
    //     isReadNotAnswered =
    //       !isUnread && (isClientMessage || isDevResponseToHotline || isInternalResponse);
    //   } else if (userService === "internal") {
    //     if (lastMessageInterne) {
    //       isInternalResponse = lastMessageInterne.serviceAnswer === "hotline to interne";
    //       isDevResponseToHotline = lastMessageInterne.serviceAnswer === "interne";
    //     }
    //     if (lastMessage) {
    //       isClientMessage = lastMessage.serviceAnswer === "client";
    //       isHotlineMessage = lastMessage.serviceAnswer === "hotline to client";
    //     }
    //     isReadNotAnswered =
    //       !isUnread &&
    //       (isInternalResponse ||
    //         isDevResponseToHotline ||
    //         isClientMessage ||
    //         isHotlineMessage);
    //   } else if (userService === "client") {
    //     if (lastMessage) {
    //       isHotlineMessage = lastMessage.serviceAnswer === "hotline to client";
    //       isClientMessage = lastMessage.serviceAnswer === "client";
    //     }
    //     isReadNotAnswered = !isUnread && isHotlineMessage;
    //   }

    //   console.log("Debug - Final values:", {
    //     isUnread,
    //     isClientMessage,
    //     isHotlineMessage,
    //     isInternalResponse,
    //     isDevResponseToHotline,
    //     isReadNotAnswered,
    //   });

    //   return {
    //     isUnread,
    //     isClientMessage,
    //     isHotlineMessage,
    //     isInternalResponse,
    //     isDevResponseToHotline,
    //     isReadNotAnswered,
    //   };
    // },
    lastMessage(fiche) {
      if (
        typeof fiche.last_message_interne != "undefined" &&
        typeof this.getMessagerieData().rightsUser.hotline != "undefined"
      ) {
        return fiche.last_message_interne.time > fiche.last_message.time
          ? fiche.last_message_interne
          : fiche.last_message;
      } else {
        return fiche.last_message;
      }
    },
    checkMessageToday(time) {
      let ymdNow = moment().format("MM-DD-YYYY");
      let ymdMsg = moment(time * 1000).format("MM-DD-YYYY");
      if (ymdNow == ymdMsg) {
        return true;
      } else {
        return false;
      }
    },
    dateMessageChat(time, format = null) {
      return moment(time * 1000).format(format);
    },
    usernameUser(user) {
      return user.firstname + " " + user.lastname.toUpperCase();
    },
    getNbUserFiches(fiche) {
      return fiche.fiche.users
        .filter((u) => {
          if (u.id != this.user.id) {
            return u;
          }
          if (u.id != fiche.fiche.userCreate.id) {
            return u;
          }
        })
        .sort(this.compareUsers).length;
    },
    minifyMessage(message) {
      return message.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.messagerie-list--mini--item {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  transition: all 0.2s ease-in-out;

  &:hover::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }

  &.theme-light {
    &.unread-client,
    &.read-not-answered-client {
      font-weight: bold;
      color: #151515;
      border: #cccccc 1px solid;
      box-shadow: 0 0 10px 3px #ccc inset;
    }

    &.unread-internal,
    &.read-not-answered-internal {
      font-weight: bold;
      color: #151515;
      border: #009688 1px solid;
      box-shadow: 0 0 10px 3px #ccc inset;
    }
  }

  &.theme-dark {
    &.new_fiche,
    &.unread-client,
    &.read-not-answered-client {
      font-weight: bold;
      color: #fff;
      border: #fff 1px solid;
      box-shadow: 0 0 10px 3px #ccc inset;
      background-color: rgb(95, 95, 95);
    }

    &.unread-internal,
    &.read-not-answered-internal {
      font-weight: bold;
      color: #fff;
      border: #009688 1px solid;
      box-shadow: 0 0 10px 3px #009688 inset;
    }
  }

  &.unread-hotline {
    border-left: 4px solid var(--v-primary-base);
  }

  &.read-not-answered-client,
  &.read-not-answered-internal {
    border-left: 4px solid var(--v-warning-base);
  }

  &.selected {
    > ::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-primary-base) !important;
    }
  }

  &.new_fiche {
    font-weight: bold;
    color: #151515;
    border: #cccccc 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
    > ::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-warning-base) !important;
    }
  }

  &.archived {
    > ::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-info-base) !important;
    }
  }

  &.more-visibilty {
    font-weight: bold;
    color: #e9e9e9;
    border: #cccccc 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
    background-color: rgb(139, 135, 135);
  }

  &.urgency-fiche-inset {
    // Styles spécifiques pour les fiches urgentes si nécessaire
  }
}

.chip-truncate {
  max-width: calc(100% - 16px);

  span {
    line-height: 17px;
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
}
</style>
